import { Action, Reducer } from 'redux'
import { OrganisationResponse, OrganisationTinNumberResponse } from '../ApiClient'

const SET_ORGANISATION_INFORMATION = 'SET_ORGANISATION_INFORMATION'
const SET_ORGANISATIONS = 'SET_ORGANISATIONS'

export interface OrganisationState {
    currentOrganisationId: string,
    currentOrganisationName: string,
    currentOrganisationTinNumber: string, //!
    currentOrganisationTinNumbers: OrganisationTinNumberResponse[], //!
    organisations: OrganisationResponse[],
    loading: boolean
}

export interface SetOrganisationInformationAction { 
    readonly type: 'SET_ORGANISATION_INFORMATION',
    organisationId: string,
    name: string,
    tinNumber: string 
}

export interface SetOrganisationsAction {
    readonly type: 'SET_ORGANISATIONS',
    organisations: OrganisationResponse[]
}

export type OrganisationActions = SetOrganisationInformationAction | SetOrganisationsAction;

export const actionCreators = {
    setCurrentOrganisationInformation: (organisationId: string, name: string, tinNumber: string) => (
        { 
            type: SET_ORGANISATION_INFORMATION,
            organisationId: organisationId,
            name: name,
            tinNumber: tinNumber 
        } as SetOrganisationInformationAction),
    setOrganisations: (organisations: OrganisationResponse[]) => (
        {
            type: SET_ORGANISATIONS,
            organisations: organisations
        } as SetOrganisationsAction),
};

export const reducer: Reducer<OrganisationState> = (state: OrganisationState | undefined, incomingAction: Action): OrganisationState => {
    if (state === undefined) {
        return { currentOrganisationId: '', currentOrganisationName: '', currentOrganisationTinNumber: '', currentOrganisationTinNumbers: [], organisations: [], loading: true }
    }

    const action = incomingAction as OrganisationActions
    switch (action.type) {
        case SET_ORGANISATION_INFORMATION:
            return {
                currentOrganisationId: (action as SetOrganisationInformationAction).organisationId,
                currentOrganisationName: (action as SetOrganisationInformationAction).name,
                currentOrganisationTinNumber: (action as SetOrganisationInformationAction).tinNumber,
                currentOrganisationTinNumbers: (action as SetOrganisationInformationAction).tinNumbers,
                organisations: state.organisations,
                loading: true
            }
        case SET_ORGANISATIONS:            
            let org = (action as SetOrganisationsAction).organisations.find(o => o.selected === true);
            
            if (org === undefined){
                org = (action as SetOrganisationsAction).organisations[0];  
            }
            
            return {
                currentOrganisationId: org.id!,
                currentOrganisationName: org.name!,
                currentOrganisationTinNumber: org.tinNumber!,
                currentOrganisationTinNumbers: org.organisationTinNumbers!,
                organisations: (action as SetOrganisationsAction).organisations,
                loading: true
            }
        default:
            return state
    }
};